import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: " */ "../views/app"),
    children: [
      {
        path: "",
        component: () => import(/* webpackChunkName: " */ "../views/app/Home"),
      },
    ],
  },
];

const router = new VueRouter({
  linkExactActiveClass: "active", // active class for *exact* links.
  linkActiveClass: "active", // active class for non-exact links
  routes,
  mode: "history",
});
// router.beforeEach(AuthGuard);
export default router;
